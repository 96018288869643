import axios from "axios";
import { API_HOST } from "../constants";

const performLogin = async function (
  username: string,
  password: string
): Promise<string> {
  try {
    const response = await axios.post<{ data?: { apiKey: string } }>(
      `${API_HOST}/backend/v1/auth/login`,
      {
        username,
        password,
      }
    );
    return response.data?.data?.apiKey || "";
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return "";
  }
};

export default performLogin;

/* eslint-disable prefer-spread */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { FC, Fragment, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, Link, useLocation } from "react-router-dom";
import { CoreContext } from "../contexts";
import { useDisplayAlert } from "../hooks";
import { getPlugin } from "../requests";
import { resetCache, setAmazonSettings, testPlugin } from "../requests/plugins";
import { IProduct } from "../types";
import { AmazonForm } from "../types/amazon";
import Header from "./Header";
import Loader from "./Loader";
import ScrollToTop from "./ScrollToTop";
import Tooltip from "./Tooltip";

const AmazonScraperSettings: FC = () => {
  const { state, dispatch } = useContext(CoreContext);
  const params: { domain: string } = useParams();
  const [loading, setLoading] = useState(false);
  const [testCleared, setTestCleared] = useState(true);
  const [titles, setTitles] = useState(1);
  const [prices, setPrices] = useState(1);
  const [currencies, setCurrencies] = useState(1);
  const [images, setImages] = useState(1);
  const [testResponse, setTestResponse] = useState<IProduct | string>();
  const { pathname } = useLocation();
  const { displayAlert } = useDisplayAlert();

  const { handleSubmit, register, reset, setValue } = useForm<AmazonForm>();

  // Effect for getting plugin data and setting the form state
  useEffect(() => {
    async function getPluginFromApi() {
      setLoading(true);

      const plugin = (await getPlugin(
        "amazon-scraper-settings",
        state.apiKey
      )) as AmazonForm;

      if (plugin) {
        reset(plugin);
        setValue("proxyGeoCountryCode", "DK");
        if (plugin.title?.length) {
          setTitles(plugin.title.length);
        }
      }

      setLoading(false);
    }

    void getPluginFromApi();
  }, [params.domain, reset, state.apiKey, setValue]);

  const onResetCache = async () => {
    return; // disabled
    dispatch({
      type: "set-loading",
    });
    const result = await resetCache(params.domain, state.apiKey);
    dispatch({
      type: "remove-loading",
    });

    displayAlert({
      type: result ? "success" : "error",
    });
  };

  const test = async (d: AmazonForm) => {
    if (state.loading) {
      return;
    }
    if (!d.targetUrl) {
      displayAlert({
        type: "error",
        message: "Target URL cannot be empty.",
      });
      return;
    }

    dispatch({
      type: "set-loading",
    });

    const product = await testPlugin(
      {
        targetUrl: d.targetUrl,
        proxyGeoCountryCode: d.proxyGeoCountryCode || "DK",
        domain: "amazon.com",
      },
      state.apiKey
    );

    setTestResponse(product);

    setTestCleared(false);
    dispatch({
      type: "remove-loading",
    });
  };

  const save = async (d: AmazonForm) => {
    if (state.loading) {
      return;
    }
    dispatch({
      type: "set-loading",
    });
    dispatch({
      type: "remove-alert",
    });
    const plugin: AmazonForm = {};

    if (d.title?.length && titles) {
      plugin.title = [];
      for (let i = 0; i < titles; i++) {
        if (d.title[i]) {
          if (d.title[i].selector && d.title[i].valueSelector) {
            plugin.title.push(d.title[i]);
          }
        }
      }
    }
    if (d.price?.length && prices) {
      plugin.price = [];
      for (let i = 0; i < prices; i++) {
        if (d.price[i]) {
          if (d.price[i].selector && d.price[i].valueSelector) {
            plugin.price.push(d.price[i]);
          }
        }
      }
    }
    if (d.currencyCode?.length && currencies) {
      plugin.currencyCode = [];
      for (let i = 0; i < currencies; i++) {
        if (d.currencyCode[i]) {
          if (d.currencyCode[i].selector && d.currencyCode[i].valueSelector) {
            plugin.currencyCode.push(d.currencyCode[i]);
          }
        }
      }
    }
    if (d.image?.length && images) {
      plugin.image = [];
      for (let i = 0; i < images; i++) {
        if (d.image[i]) {
          if (d.image[i].selector && d.image[i].valueSelector) {
            plugin.image.push(d.image[i]);
          }
        }
      }
    }

    const success = await setAmazonSettings(plugin, state.apiKey);

    dispatch({
      type: "remove-loading",
    });
    displayAlert({
      type: success ? "success" : "error",
    });
  };

  return (
    <div className="padding max-width">
      <form onSubmit={handleSubmit(save)}>
        <Header
          leftButton={() => (
            <Link to="/plugins" className="white white-btn small-padding">
              Back
            </Link>
          )}
          title="Amazon Scraper Settings"
          rightButton={() => (
            <button
              className="btn-white"
              disabled={state.loading}
              type="submit"
            >
              Save
            </button>
          )}
        />
        {loading ? (
          <Loader center />
        ) : (
          <div className="grid">
            <div style={{ paddingRight: 10 }}>
              {Array.from({ length: titles }).map((_, index) => (
                <Fragment key={index + "title"}>
                  <div className="field">
                    <p>Title Selector</p>
                    <input
                      type="text"
                      {...register(`title.${index}.selector`)}
                    />
                  </div>
                  <div className="field">
                    <p>Title Value Selector</p>
                    <input
                      type="text"
                      {...register(`title.${index}.valueSelector`)}
                    />
                  </div>
                </Fragment>
              ))}
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  type="button"
                  className="btn-white"
                  style={{ marginRight: 10 }}
                  onClick={() => setTitles((o) => (o += 1))}
                >
                  +
                </button>
                <button
                  type="button"
                  className="btn-white"
                  onClick={() => setTitles((o) => (o -= 1))}
                >
                  -
                </button>
              </div>
              <div style={{ padding: 20 }} />
              {Array.from({ length: prices }).map((_, index) => (
                <Fragment key={index + "price"}>
                  <div className="field">
                    <p>Price Selector</p>
                    <input
                      type="text"
                      {...register(`price.${index}.selector`)}
                    />
                  </div>
                  <div className="field">
                    <p>Price Value Selector</p>
                    <input
                      type="text"
                      {...register(`price.${index}.valueSelector`)}
                    />
                  </div>
                </Fragment>
              ))}
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  type="button"
                  className="btn-white"
                  style={{ marginRight: 10 }}
                  onClick={() => setPrices((o) => (o += 1))}
                >
                  +
                </button>
                <button
                  type="button"
                  className="btn-white"
                  onClick={() => setPrices((o) => (o -= 1))}
                >
                  -
                </button>
              </div>
              <div style={{ padding: 20 }} />
              {Array.from({ length: currencies }).map((_, index) => (
                <Fragment key={index + "currencyCode"}>
                  <div className="field">
                    <p>CurrencyCode Selector</p>
                    <input
                      type="text"
                      {...register(`currencyCode.${index}.selector`)}
                    />
                  </div>
                  <div className="field">
                    <p>CurrencyCode Value Selector</p>
                    <input
                      type="text"
                      {...register(`currencyCode.${index}.valueSelector`)}
                    />
                  </div>
                </Fragment>
              ))}
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  type="button"
                  className="btn-white"
                  style={{ marginRight: 10 }}
                  onClick={() => setCurrencies((o) => (o += 1))}
                >
                  +
                </button>
                <button
                  type="button"
                  className="btn-white"
                  onClick={() => setCurrencies((o) => (o -= 1))}
                >
                  -
                </button>
              </div>
              <div style={{ padding: 20 }} />
              {Array.from({ length: images }).map((_, index) => (
                <Fragment key={index + "image"}>
                  <div className="field">
                    <p>Image Selector</p>
                    <input
                      type="text"
                      {...register(`image.${index}.selector`)}
                    />
                  </div>
                  <div className="field">
                    <p>Image Value Selector</p>
                    <input
                      type="text"
                      {...register(`image.${index}.valueSelector`)}
                    />
                  </div>
                </Fragment>
              ))}
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  type="button"
                  className="btn-white"
                  style={{ marginRight: 10 }}
                  onClick={() => setImages((o) => (o += 1))}
                >
                  +
                </button>
                <button
                  type="button"
                  className="btn-white"
                  onClick={() => setImages((o) => (o -= 1))}
                >
                  -
                </button>
              </div>
            </div>
            <div className="divided">
              {pathname !== "/plugins/new" && (
                <div
                  className="flex-v-center"
                  style={{
                    margin: "10px 0 0 10px",
                    justifyContent: "flex-start",
                  }}
                >
                  <div style={{ display: "flex", paddingRight: 30 }}>
                    <button
                      disabled={true || state.loading}
                      type="button"
                      className="btn-white"
                      onClick={onResetCache}
                    >
                      Reset cache (disabled)
                    </button>
                    <Tooltip
                      text="Resets the 24h cache (by Product API) for the domain."
                      size={400}
                    />
                  </div>
                </div>
              )}
              <div style={{ marginTop: pathname !== "/plugins/new" ? 40 : 0 }}>
                <div className="flex-v-center">
                  <h3 style={{ margin: 0 }}>Test the scraper</h3>
                  <Tooltip
                    text="Sends a request directly to the scraper. Use it to test current settings."
                    size={520}
                  />
                </div>
                <div className="field">
                  <p style={{ width: "auto" }}>URL</p>
                  <input
                    type="text"
                    className="long"
                    {...register("targetUrl")}
                  />
                  <Tooltip
                    text="Paste a valid product URL to test."
                    size={300}
                  />
                </div>
                <div className="field">
                  <p style={{ width: "auto" }}>Proxy&apos;s geo country code</p>
                  <input type="text" {...register("proxyGeoCountryCode")} />
                  <Tooltip
                    text="Locale used by the proxy (if used)."
                    size={300}
                  />
                </div>
                <div className="flex-v-center med-padding">
                  <button
                    className="btn-white"
                    disabled={state.loading}
                    type="button"
                    onClick={handleSubmit(test)}
                  >
                    Test URL
                  </button>
                </div>
              </div>
              {state.loading && <Loader />}
              {testResponse && !testCleared ? (
                <div className="med-padding">
                  <h4 style={{ color: "green", margin: 0 }}>Result:</h4>
                  <pre>{JSON.stringify(testResponse, null, 2)}</pre>
                  <button
                    className="btn-white"
                    type="button"
                    onClick={() => setTestCleared(true)}
                  >
                    Clear result
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        )}
      </form>
      <ScrollToTop />
    </div>
  );
};

export default AmazonScraperSettings;

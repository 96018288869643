import { FC, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CoreContext } from "../contexts";
import { getBlocked } from "../requests";
import Header from "./Header";
import Loader from "./Loader";

const Blocked: FC = () => {
  const { state } = useContext(CoreContext);
  const [blocked, setBlocked] = useState<
    { website: string; counter: string }[]
  >([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getBlockedApi() {
      setLoading(true);
      setBlocked(await getBlocked(state.apiKey));
      setLoading(false);
    }

    void getBlockedApi();
  }, [state.apiKey]);

  return (
    <div className="padding max-width">
      <Header
        leftButton={() => (
          <Link to="/plugins" className="white white-btn small-padding">
            Back
          </Link>
        )}
        title="Potentially blocked sites"
      />
      {loading ? (
        <Loader center />
      ) : (
        <div className="grid">
          <div style={{ margin: "0 auto" }}>
            {!blocked.length ? null : (
              <div style={{ margin: "10px 0" }}>
                <h3 style={{ margin: 0 }}>
                  Domains with how many times each errored
                </h3>
                {blocked.map(({ website, counter }, i) => (
                  <p key={website} style={{ width: "auto" }}>
                    {`${i + 1}. ${website}: ${counter} time${
                      counter !== "1" ? "s" : ""
                    }`}
                  </p>
                ))}
              </div>
            )}
            {blocked.length ? null : (
              <p style={{ width: "auto" }}>Empty list</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Blocked;

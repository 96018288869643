import cookie from "js-cookie";
import React, { useReducer, createContext, Dispatch } from "react";
import { AlertProps } from "../components";

type Action =
  | { type: "login"; apiKey: string }
  | { type: "logout" }
  | { type: "set-alert"; alert: AlertProps }
  | { type: "remove-alert" }
  | { type: "set-loading" }
  | { type: "remove-loading" };

interface State {
  apiKey: string;
  alert?: AlertProps;
  loading: boolean;
}

const initialState: State = {
  apiKey: cookie.get("apiKey") || "",
  alert: undefined,
  loading: false,
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case "login":
      if (action.apiKey) {
        cookie.set("apiKey", action.apiKey);
        return {
          ...state,
          apiKey: action.apiKey,
        };
      }
      return state;
    case "logout":
      cookie.remove("apiKey");
      return {
        ...state,
        apiKey: "",
      };
    case "set-alert":
      if (!action.alert) {
        return state;
      }
      return {
        ...state,
        alert: action.alert,
      };
    case "remove-alert":
      return {
        ...state,
        alert: undefined,
      };
    case "set-loading":
      return {
        ...state,
        loading: true,
      };
    case "remove-loading":
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

const CoreContext = createContext<{ state: State; dispatch: Dispatch<Action> }>(
  {
    state: initialState,
    dispatch: () => null,
  }
);

const CoreContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };

  return <CoreContext.Provider value={value}>{children}</CoreContext.Provider>;
};

export { CoreContext, CoreContextProvider };

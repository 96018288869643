import React from "react";

export interface Props {
  type: "success" | "error" | "info";
  message?: string;
}

const getText = ({ type, message }: Props) => {
  if (type === "success") {
    return {
      title: "Success",
      message: message ?? "Your changes have been saved",
    };
  }
  if (type === "info" && message) {
    return {
      title: "Info",
      message: message,
    };
  }
  return {
    title: "Error",
    message: message ?? "Something went wrong",
  };
};

const Alert: React.FC<{ alert: Props }> = ({ alert }) => (
  <div className={"alert " + alert.type}>
    <h2>{getText(alert).title}</h2>
    <p>{getText(alert).message}</p>
  </div>
);

export default Alert;

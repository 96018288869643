import React from "react";
import { AlertProps } from "../components";
import { CoreContext } from "../contexts";

export default function useDisplayAlert() {
  const { dispatch } = React.useContext(CoreContext);

  const displayAlert = (alert: AlertProps, time = 5000) => {
    dispatch({
      type: "set-alert",
      alert,
    });
    setTimeout(
      () =>
        dispatch({
          type: "remove-alert",
        }),
      time
    );
  };

  return {
    displayAlert,
  };
}

import React from "react";
import { CoreContext } from "../contexts";
import Alert from "./Alert";

const Addons: React.FC = () => {
  const { state } = React.useContext(CoreContext);

  return (
    <>
      {state.alert && <Alert alert={state.alert} />}
      {state.loading && <div className="spinner" />}
    </>
  );
};

export default Addons;

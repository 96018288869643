/* eslint-disable @typescript-eslint/no-misused-promises */
import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { CoreContext } from "../contexts";
import { useDisplayAlert } from "../hooks";
import { performLogin } from "../requests";
import Header from "./Header";

interface FormValues {
  username: string;
  password: string;
}

const Login: React.FC = () => {
  const history = useHistory();
  const { state, dispatch } = React.useContext(CoreContext);
  const { displayAlert } = useDisplayAlert();

  const { handleSubmit, register } = useForm<FormValues>({
    defaultValues: { username: "", password: "" },
  });

  React.useEffect(() => {
    if (state.apiKey) {
      history.push("/plugins");
    }
  }, [history, state.apiKey]);

  const save = async (d: FormValues) => {
    try {
      dispatch({
        type: "set-loading",
      });
      const apiKey = await performLogin(d.username, d.password);
      if (apiKey) {
        dispatch({
          type: "login",
          apiKey,
        });
        history.push("/plugins");
      } else {
        throw new Error("Err");
      }
    } catch (err) {
      displayAlert(
        {
          type: "error",
          message:
            "Failed to login with the given credentials, please try again.",
        },
        3000
      );
    }
    dispatch({
      type: "remove-loading",
    });
  };

  return (
    <div>
      <Header title="Gowish Scraper Admin Panel" />
      <div className="login">
        <form onSubmit={handleSubmit(save)}>
          <div className="field loginField">
            <p>Username</p>
            <input type="text" {...register("username")} />
          </div>
          <div className="field loginField">
            <p>Password</p>
            <input type="password" {...register("password")} />
          </div>
          <button
            disabled={state.loading}
            className="btn-white loginBtn"
            type="submit"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;

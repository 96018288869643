import React, { ChangeEvent, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import { CoreContext } from "../../contexts";
import { getPlugins } from "../../requests";
import Header from "../Header";
import NewPluginModal from "./NewPluginModal";

let timer: NodeJS.Timeout | undefined = undefined;
const debounce = (fn: (...args: unknown[]) => Promise<void>, delay: number) => {
  if (timer) {
    clearTimeout(timer);
  }
  timer = setTimeout(() => void fn(), delay);
};

const Plugins: React.FC = () => {
  const history = useHistory();
  const [search, setSearch] = React.useState("");
  const [plugins, setPlugins] = React.useState<string[]>([]);
  const [showNew, setShowNew] = React.useState(true);
  const [inProgress, setInProgress] = React.useState(false);
  const { state, dispatch } = React.useContext(CoreContext);

  const onLogout = () => {
    dispatch({ type: "logout" });
    history.push("/");
  };

  const onNewClick = () => {
    setShowNew(true);
  };

  const onAmazonClick = () => {
    history.push("/plugins/amazon-scraper-settings");
  };

  const debounceCb = useCallback(
    (value: string) => {
      debounce(async () => {
        dispatch({
          type: "set-loading",
        });

        const plugins = await getPlugins(state.apiKey, value);
        setPlugins(plugins);

        dispatch({
          type: "remove-loading",
        });
        setInProgress(false);
      }, 2000);
    },
    [dispatch, state.apiKey]
  );

  const onSearch = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (!inProgress) {
      setInProgress(true);
    }
    if (state.loading) {
      return;
    }

    setSearch(target.value);
    debounceCb(target.value);
  };

  return (
    <div className="max-width container conversions-list padding center">
      <Header
        title="Scraper Admin - Domains"
        rightButton={() => (
          <button className="btn-white" onClick={onLogout}>
            Logout
          </button>
        )}
      />
      <div className="add-btn-wrapper">
        <button className="add-btn" onClick={onNewClick}>
          Search or Create by URL
        </button>
        <br />
        <br />
        <button
          className="add-btn"
          onClick={onAmazonClick}
          style={{ background: "orange" }}
        >
          Amazon Settings
        </button>
      </div>
      <div>
        <div className="field" style={{ justifyContent: "center" }}>
          <input
            placeholder="Search by domain name"
            type="text"
            onChange={(e) => void onSearch(e)}
            value={search}
            className="search"
          />
        </div>
        <div className="plugins">
          {plugins
            .filter((e) => (search ? e.includes(search) : true))
            .map((plugin, index) => {
              return (
                <Link
                  className="btn"
                  to={"plugins/" + plugin}
                  key={`plugin-${index}`}
                >
                  <div className="med-padding overflow-dots plugin-link">
                    {plugin}
                  </div>
                </Link>
              );
            })}
          {plugins.length
            ? null
            : search && !inProgress
            ? "No results."
            : "Enter a search term to see domains."}
        </div>
      </div>
      {showNew && <NewPluginModal onClose={() => setShowNew(false)} />}
    </div>
  );
};
export default Plugins;

const parseDomain = (s: string) => {
  if (!s) {
    return;
  }
  if (!s.startsWith("http")) {
    s = "https://" + s;
  }
  try {
    const { hostname } = new URL(s);
    return hostname.startsWith("www.") ? hostname.substring(4) : hostname;
  } catch (e) {
    return null;
  }
};

export default parseDomain;

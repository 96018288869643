import React, { useReducer, createContext, Dispatch } from "react";

type Action = { type: "set-plugins"; plugins: string[] };

interface State {
  plugins: string[];
}

const initialState: State = {
  plugins: [],
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case "set-plugins":
      if (!action.plugins) {
        return state;
      }
      return {
        ...state,
        plugins: action.plugins,
      };
    default:
      return state;
  }
};

const PluginsContext = createContext<{
  state: State;
  dispatch: Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => null,
});

const PluginsContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };

  return (
    <PluginsContext.Provider value={value}>{children}</PluginsContext.Provider>
  );
};

export { PluginsContextProvider, PluginsContext };

import { FC } from "react";

interface Props {
  center?: boolean;
}

const Loader: FC<Props> = ({ center }) => {
  return (
    <div
      className="loader"
      {...(center ? { style: { margin: "20px auto" } } : {})}
    >
      <div className="bar" />
    </div>
  );
};

export default Loader;

import React from "react";
import { Route, Redirect } from "react-router-dom";
import { CoreContext } from "../contexts";

interface IProps {
  component: React.FC<unknown>;
  path: string;
  exact?: boolean;
}

function PrivateRoute({ component: Comp, path, exact, ...rest }: IProps) {
  const { state } = React.useContext(CoreContext);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const routeComponent = (props: any) =>
    state.apiKey ? <Comp {...props} /> : <Redirect to={{ pathname: "/" }} />;
  return <Route path={path} exact={exact} {...rest} render={routeComponent} />;
}

export default PrivateRoute;

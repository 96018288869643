import React from "react";

interface Props {
  size: number;
  text: string;
}

const Tooltip: React.FC<Props> = ({ size, text }) => (
  <>
    <div className="tooltip">
      <span className="material-symbols-outlined">help</span>
      <span
        className="tooltiptext"
        style={{ width: size, marginLeft: (-1 * size) / 2 }}
      >
        {text}
      </span>
    </div>
  </>
);

export default Tooltip;

/* eslint-disable jsx-a11y/click-events-have-key-events, @typescript-eslint/no-misused-promises */
import React from "react";
import { useHistory } from "react-router-dom";
import { CoreContext } from "../../contexts";
import { useDisplayAlert } from "../../hooks";
import { getPlugin } from "../../requests";
import { parseDomain } from "../../utils";

interface Props {
  onClose: () => void;
}

const NewPluginModal: React.FC<Props> = ({ onClose }) => {
  const [url, setUrl] = React.useState("");
  const { displayAlert } = useDisplayAlert();
  const { state } = React.useContext(CoreContext);
  const history = useHistory();

  const onSubmit = async () => {
    if (!url) {
      displayAlert({
        type: "error",
        message: "URL cannot be empty.",
      });
      return;
    }
    const domain = parseDomain(url);
    if (!domain) {
      displayAlert({
        type: "error",
        message: "URL must be valid.",
      });
      return;
    }
    const plugin = await getPlugin(domain, state.apiKey);
    if (!plugin) {
      history.push(
        `/plugins/new?product=${encodeURIComponent(
          url
        )}&domain=${encodeURIComponent(domain)}`
      );
    } else {
      displayAlert(
        {
          type: "info",
          message: "Plugin already exists.",
        },
        3000
      );
      history.push(
        "/plugins/" + domain + "?product=" + encodeURIComponent(url)
      );
    }
  };

  return (
    <div
      className="new-plugin-modal"
      onClick={() => onClose()}
      onKeyDown={(e) =>
        e.key === "Escape" ? onClose() : e.key === "Enter" && void onSubmit()
      }
      role="presentation"
    >
      <div onClick={(e) => e.stopPropagation()} role="presentation">
        <h1>Enter a valid product URL:</h1>
        <div className="field addField">
          <input
            type="text"
            value={url}
            onChange={({ target }) => setUrl(target.value)}
            autoFocus
          />
        </div>
        <button className="btn-white close-btn" onClick={onClose}>
          Close
        </button>
        <button
          disabled={!url}
          className="btn-white loginBtn"
          onClick={onSubmit}
        >
          Search or Create
        </button>
      </div>
    </div>
  );
};

export default NewPluginModal;

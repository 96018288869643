/* eslint-disable no-console */
import axios, { isAxiosError } from "axios";
import { API_HOST, GAPI_HOST } from "../constants";
import { IPlugin, IProduct } from "../types";
import { AmazonForm } from "../types/amazon";

interface BackendError {
  message?: string;
  code?: number;
}

interface GetPluginsRequest {
  plugins?: string[];
}

interface GetPluginRequest {
  data?: Omit<IPlugin, "proxy" | "imageProxy"> & {
    proxy?: string;
    imageProxy?: string;
  };
}

interface GetDomainRuleRequest {
  blacklisted: string[];
  whitelisted: string[];
}

type GetBlockedRequest = { website: string; counter: string }[];
type GetLibrariesRequest = { website: string; libraries: string[] }[];

interface TestPluginRequest {
  data?: IProduct;
}

interface ITestPlugin extends IPlugin {
  targetUrl: string;
  proxyGeoCountryCode: string;
}

function proxiesAreValid(plugin: GetPluginRequest["data"]): boolean {
  if (!plugin) {
    return false;
  }

  const proxyIsValid = plugin.proxy
    ? [
        "residential",
        "datacenter",
        "static",
        "unlocker",
        "newunlocker",
      ].includes(plugin.proxy)
    : true;
  const imageProxyIsValid = plugin.imageProxy
    ? [
        "residential",
        "datacenter",
        "static",
        "unlocker",
        "newunlocker",
      ].includes(plugin.imageProxy)
    : true;

  return proxyIsValid && imageProxyIsValid;
}

const getPlugins = async function (
  apiKey: string,
  search = ""
): Promise<string[]> {
  try {
    if (!search || search.length < 3) {
      return [];
    }
    const response = await axios.get<GetPluginsRequest>(
      `${API_HOST}/backend/v1/plugins?search=${search}`,
      {
        headers: { Authorization: `Bearer ${apiKey}` },
      }
    );
    if (response.data?.plugins?.length) {
      return response.data.plugins;
    }
    return [];
  } catch (e) {
    console.error(isAxiosError<BackendError>(e) ? e.message : e);
    return [];
  }
};

const getPlugin = async function (domain: string, apiKey: string) {
  try {
    const plugin = await axios.get<GetPluginRequest>(
      `${API_HOST}/backend/v1/plugins/${domain}`,
      {
        headers: { Authorization: `Bearer ${apiKey}` },
      }
    );
    if (plugin?.data?.data) {
      if (proxiesAreValid(plugin.data.data)) {
        return plugin.data.data as IPlugin;
      } else {
        return { ...plugin.data.data, proxy: undefined, imageProxy: undefined };
      }
    }
    return undefined;
  } catch (e) {
    console.error(isAxiosError<BackendError>(e) ? e.message : e);
    return undefined;
  }
};

const getWhitelistedDomainRules = async function (
  domain: string,
  apiKey: string
): Promise<string[]> {
  try {
    const domainRules = await axios.get<GetDomainRuleRequest>(
      `${API_HOST}/backend/v1/plugins/${domain}/rules`,
      {
        headers: { Authorization: `Bearer ${apiKey}` },
      }
    );
    return domainRules.data?.whitelisted ?? [];
  } catch (e) {
    console.error(isAxiosError<BackendError>(e) ? e.message : e);
    return [];
  }
};

const getBlocked = async function (apiKey: string) {
  try {
    const blocked = await axios.get<GetBlockedRequest>(
      `${API_HOST}/backend/v1/blocked`,
      {
        headers: { Authorization: `Bearer ${apiKey}` },
      }
    );
    return blocked.data;
  } catch (e) {
    console.error(isAxiosError<BackendError>(e) ? e.message : e);
    return [];
  }
};

const getLibraries = async function (apiKey: string) {
  try {
    const libraries = await axios.get<GetLibrariesRequest>(
      `${API_HOST}/scrapers/v1/libraries`,
      {
        headers: { Authorization: `Bearer ${apiKey}` },
      }
    );
    return libraries.data;
  } catch (e) {
    console.error(isAxiosError<BackendError>(e) ? e.message : e);
    return [];
  }
};

const testPlugin = async function (
  plugin: ITestPlugin,
  apiKey: string
): Promise<IProduct | string> {
  try {
    const response = await axios.post<TestPluginRequest>(
      `${API_HOST}/backend/v1/plugins/test`,
      plugin,
      { headers: { Authorization: `Bearer ${apiKey}` } }
    );
    return response.data?.data ?? "Not defined";
  } catch (e: unknown) {
    console.error(isAxiosError<BackendError>(e) ? e.message : e);
    if (isAxiosError<BackendError>(e)) {
      return e.response ? e.response.data.message || e.message : e.message;
    }
    return "Error";
  }
};

const setPlugin = async function (
  plugin: IPlugin,
  apiKey: string
): Promise<boolean> {
  try {
    await axios.post(`${API_HOST}/backend/v1/plugins`, plugin, {
      headers: { Authorization: `Bearer ${apiKey}` },
    });
    return true;
  } catch (e) {
    console.error(isAxiosError<BackendError>(e) ? e.message : e);
    return false;
  }
};

const setAmazonSettings = async function (
  plugin: AmazonForm,
  apiKey: string
): Promise<boolean> {
  try {
    await axios.post(`${API_HOST}/backend/v1/plugins/amazon`, plugin, {
      headers: { Authorization: `Bearer ${apiKey}` },
    });
    return true;
  } catch (e) {
    console.error(isAxiosError<BackendError>(e) ? e.message : e);
    return false;
  }
};

const deletePlugin = async function (
  domain: string,
  apiKey: string
): Promise<boolean> {
  try {
    await axios.delete(`${API_HOST}/backend/v1/plugins/${domain}`, {
      headers: { Authorization: `Bearer ${apiKey}` },
    });
    return true;
  } catch (e) {
    console.error(isAxiosError<BackendError>(e) ? e.message : e);
    return false;
  }
};

const resetCache = async function (
  domain: string,
  apiKey: string
): Promise<boolean> {
  try {
    await axios.post(
      `${API_HOST}/backend/v1/report`,
      {
        type: "reset-cache",
        data: domain,
      },
      {
        headers: { Authorization: `Bearer ${apiKey}` },
      }
    );
    const response = await axios.post<
      string,
      { data?: { data?: { admin?: { resetDomainCache: boolean } } } }
    >(
      `${GAPI_HOST}/graphql`,
      JSON.stringify({
        operationName: "resetCache",
        variables: {},
        query: `mutation resetCache {\n admin {\n resetDomainCache(domain: "${domain}")\n }\n}\n`,
      }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return !!response.data?.data?.admin?.resetDomainCache;
  } catch (e) {
    console.error(isAxiosError<BackendError>(e) ? e.message : e);
    return false;
  }
};

export {
  getPlugins,
  getPlugin,
  testPlugin,
  setPlugin,
  deletePlugin,
  resetCache,
  setAmazonSettings,
  getWhitelistedDomainRules,
  getBlocked,
  getLibraries,
};

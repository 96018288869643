import React from "react";

interface Props {
  leftButton?: () => JSX.Element;
  rightButton?: () => JSX.Element;
  title: string;
}

const Header: React.FC<Props> = ({ leftButton, rightButton, title }) => (
  <header style={{ zIndex: 999 }}>
    <div className="header-inner">
      {leftButton ? leftButton() : <div />}
      <h1 className="white">{title}</h1>
      {rightButton ? rightButton() : <div />}
    </div>
  </header>
);

export default Header;

import { FC, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CoreContext } from "../contexts";
import { getLibraries } from "../requests";
import Header from "./Header";
import Loader from "./Loader";

const Libraries: FC = () => {
  const { state } = useContext(CoreContext);
  const [libraries, setLibraries] = useState<
    { website: string; libraries: string[] }[]
  >([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getLibrariesApi() {
      setLoading(true);
      setLibraries(await getLibraries(state.apiKey));
      setLoading(false);
    }

    void getLibrariesApi();
  }, [state.apiKey]);

  return (
    <div className="padding max-width">
      <Header
        leftButton={() => (
          <Link to="/plugins" className="white white-btn small-padding">
            Back
          </Link>
        )}
        title="Detected Libraries"
      />
      {loading ? (
        <Loader center />
      ) : (
        <div className="grid">
          <div style={{ margin: "0 auto" }}>
            {!libraries.length ? null : (
              <div style={{ margin: "10px 0" }}>
                <h3 style={{ margin: 0 }}>Domains with libraries detected</h3>
                {libraries.map(({ website, libraries }, i) => (
                  <p key={website} style={{ width: "auto" }}>
                    {`${i + 1}. ${website}: ${libraries.join(", ")}`}
                  </p>
                ))}
              </div>
            )}
            {libraries.length ? null : (
              <p style={{ width: "auto" }}>Empty list</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Libraries;
